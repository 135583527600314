import React, { useMemo, useRef } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { isLocalDev, isPrPreview } from "../constants";
import {
    EA_PRODUCT_INTRO_PRODUCTION_URL,
    EA_PRODUCT_INTRO_STAGING_URL,
} from "../constants/urls";
import {
    PRODUCT_INTRO_CLOSE_BUTTON_CLICKED,
    PRODUCT_INTRO_COMPLETED,
    PRODUCT_INTRO_PAGE_LOADED,
    PRODUCT_INTRO_SETUP,
    PRODUCT_INTRO_SETUP_DATA_RECEIVED,
} from "../types/productIntroTypes";

const Iframe = styled.iframe`
    width: 100%;
    height: 100%;
    min-height: 100vh;
    border: 0px;
`;

const getProductIntroUrl = () => {
    if (isLocalDev() || isPrPreview()) {
        // staging for PR preview & local
        return EA_PRODUCT_INTRO_STAGING_URL;
    } else {
        // default to production
        return EA_PRODUCT_INTRO_PRODUCTION_URL;
    }
};

// To enable special debugging for message routing, set localStorage "EA_DEBUG" to 'true' on the website iframe
const debugLog = (...args) => {
    try {
        if (typeof window !== undefined && window.localStorage) {
            if (window.localStorage.getItem("EA_DEBUG") === "true") {
                console.log("[website-log]", ...args);
            }
        }
        // tslint:disable-next-line no-empty
    } catch (e) {}
};

const ProductIntroShim = () => {
    if (typeof window === "undefined") return null;
    debugLog("loaded ProductIntroShim");

    const productIntroUrl = getProductIntroUrl();
    const endUserDomainOrigin = useRef(null);
    const productIntroIframeRef = useRef(null);

    // only ever use one iframe, otherwise the setup messages will have to be replayed
    const productIntroIframe = useMemo(
        () => <Iframe ref={productIntroIframeRef} src={productIntroUrl} />,
        []
    );

    const postMessageToJiraApp = (data) => {
        debugLog(
            "postMessageToJiraApp",
            data,
            endUserDomainOrigin.current || "*"
        );
        // Send the message out to the jira instance.
        if (window.parent !== window) {
            window.parent.postMessage(data, endUserDomainOrigin.current || "*");
        }
    };

    const postMessageToProductIntro = (data) => {
        debugLog("postMessageToProductIntro", data, productIntroUrl);
        // Send the setup data to the child product-intro iframe
        if (productIntroIframeRef.current) {
            productIntroIframeRef?.current?.contentWindow.postMessage(
                {
                    ...data,
                    origin: window.location.origin, // tell product intro to reply to the shim instead of jira
                },
                productIntroUrl
            );
        }
    };

    // Forwards messages between IFrames
    const handleMessage = (message) => {
        switch (message.data.type) {
            case PRODUCT_INTRO_PAGE_LOADED:
                // forward PRODUCT_INTRO_PAGE_LOADED -> jira app
                debugLog("handleMessage", productIntroUrl, message);
                postMessageToJiraApp(message.data);

                break;
            case PRODUCT_INTRO_SETUP:
                // forward PRODUCT_INTRO_SETUP -> product intro
                // this contains form fill info from jira app data
                debugLog("handleMessage", productIntroUrl, message);

                // The setupData contains the origin of the Jira app
                // It is used to keep track of the origin, and use it for window messaging
                endUserDomainOrigin.current = message.data.origin;
                postMessageToProductIntro(message.data);

                break;
            case PRODUCT_INTRO_SETUP_DATA_RECEIVED: // tells jira the form loaded ok
            case PRODUCT_INTRO_CLOSE_BUTTON_CLICKED: // tells jira app close clicked
            case PRODUCT_INTRO_COMPLETED: // tells jira app form submitted
                // forward form fill/complete/cancel events to jira app.
                debugLog("handleMessage", productIntroUrl, message);
                postMessageToJiraApp(message.data);

                break;
            default:
                break;
        }
    };

    // Listen for messages and forward actions to IFrames based on the message type
    window.addEventListener("message", handleMessage);

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
            {productIntroIframe}
        </>
    );
};

export default ProductIntroShim;
